.plans-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.plans-plan h3 {
    margin-bottom: 0.8rem;
}

.plans-plan small {
    margin-bottom: 2rem;
}

.plans-plan h1 {
    color: var(--color-primary-variant);
    display: inline;
}

.plans-plan h2 {
    display: inline;
    color: var(--color-gray-400);
}

.plans-plan h4 {
    margin-top: 2rem;
}

.plans-plan p {
    margin-top: 1rem;
    color: var(--color-gray-100);
}

.plans-plan p.disabled {
    color: var(--color-gray-400);
}

.plans-plan button {
    margin-top: 3rem;
    cursor: pointer;
}





/* MEDIA QUERIES (medium screens) */

@media screen and (max-width: 1024px) {
    .plans-container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}




/* MEDIA QUERIES (small screens) */

@media screen and (max-width: 600px) {
    .plans-container {
        grid-template-columns: 1fr;
    }

    .plans-plan {
        width: 90%;
        margin: 0 auto;
    }
}