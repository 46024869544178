.main-header {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main-header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main-header-left {
    margin-top: -3rem;
}

.main-header-left h4 {
    margin-bottom: 1rem;
    color: var(--color-secondary);
    font-weight: 400;
}

.main-header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
}

.main-header-right {
    display: grid;
    place-items: center;
    position: relative;
}

.main-header-circle {
    width: 43rem;
    height: 22.7rem;
    background: linear-gradient(75deg, 
    var(--color-secondary), transparent);
    border-radius: 10%;
    position: absolute;
    transition: var(--transition);
}

.main-header-right:hover .main-header-circle {
    filter: blur(15px);
}

.main-header-image {
    position: relative;
}


/* ==== Programs === */


.programs {
    margin-top: 4rem;
}

.programs-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.programs-program a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.programs-program:hover a {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}



/* ==== Values === */

.values-container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
}

.values-image {
    filter: saturate(0.25);
    transition: var(--transition);
}

.values-image:hover {
    filter: saturate(1);
}

.values-right > p {
    margin: 1.5rem 0 5rem;
}

.values-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}

.card.values-value {
    padding-top: 3.5rem;
    text-align: left;
    position: relative;
}

.card.values-value span {
    position: absolute;
    top: -1.5rem;
}





/* ==== FAQs === */

.faqs-wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq {
    background: var(--color-gray-500);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-icon {
    background: transparent;
    color: var(--color-gray-100);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
}


/* ==== Testimonials === */


.testimonials {
    width: 50rem;
    margin-inline: auto;
}

.testimonials-head {
    justify-content: center;
}

.card.tetsimonials-card {
    position: relative;
    text-align: left;
    margin-top: 5rem;
}

.testimonials-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 1.5rem;
    border: 2px solid transparent;
    overflow: hidden;
    position: absolute;
    top: -2rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5ren 2rem rgba(26, 26, 54, 0.4);
    transition: var(--transition);
}

.testimonials:hover 
.testimonials-avatar {
    border-radius: 50%;
    border-color: var(--color-gray-400);
}

.testimonials-quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}

.card small.testimonials-job-titile {
    margin-top: 0.3rem;
}

.testimonials-btn-container {
    width: fit-content;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials-btn {
    background: transparent;
    cursor: pointer;
}

.testimonials-btn svg {
    fill: var(--color-primary);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}




/* ==== Footer === */

footer {
    background: var(--color-primary);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer-container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer-container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer-container article p {
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.footer-socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
    margin-right: 1rem;
}

.footer-socials a svg {
    color: var(--color-gray-600);
}

.footer-socials a:hover {
    border-color: var(--color-gray-100);
    background: transparent;
}

.footer-copyright {
    color: var(--color-gray-100);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-primary-variant);
    margin-top: 5rem;
}




/* Media Queries (medium screen) */

@media screen and (max-width: 1024px) {
    /* MAIN HEADER */
    .main-header {
        height: fit-content;
        padding: 12rem 0;
    }

    .main-header-container {
        gap: 0;
    }

    .main-header-circle {
        width: 20rem;
        height: 12.4rem;
    }


    /* OUR PROGRAMS */
    .programs-wrapper {
        grid-template-columns: 1fr 1fr;
    }


    /* VALUES */
    .values-container {
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }

    .values-wrapper {
        gap: 4rem 3rem;
    }

    .values-image {
        display: none;
    }


    /* FAQs */
    .faqs-wrapper {
        grid-template-columns: 1fr;
    }


    /* TESTIMONIALS */
    .testimonials {
        width: 75%;
    }


    /* FOOTER */
    .footer-container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }

}



/* Media Queries (small screen) */

@media screen and (max-width: 600px) {
    /* MAIN HEADER */
    .main-header {
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
    }

    .main-header-image, .main-header-circle {
        display: none;
    }

    .main-header-container {
        grid-template-columns: 1fr;
    }


    /* OUR PROGRAMS */
    .programs-wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .programs-program {
        width: 84%;
        margin: 0 auto;
    }


    /* VALUES */
    .values-wrapper{
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .card.values-value {
        width: 84%;
        margin: 0 auto;
    }


    /* FAQs */
    .faqs-wrapper {
        gap: 1rem;
        margin-top: 3rem;
    }


    /* TASTIMONIALS */
    .testimonials {
        width: 100%;
    }


    /* FOOTER */
    footer {
        margin-top: 7rem;
    }

    .footer-container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer-container article {
        align-items: center;
    }

    .footer-container article p {
        text-align: center;
    }

}